<template>
  <div class="customer-c">
            <div class="top-box">
              <div class="top-title">C端客户管理</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick()"><i class="el-icon-search"></i> 查询</div>
                <div @click="handleAddClick()" class="btn"><i class="el-icon-plus"></i> 新建</div>
                <div @click="handlePushClick()" class="btn">分配学生</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="6" class="form-item">
                  <div class="label">服务区分</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="service"
                      v-loading="serviceListLoading"
                    element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option 
                          v-for="item in serviceList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">学生姓名</div>
                  <div class="input-box">
                      <el-input v-model="studentName" placeholder="请输入学生姓名" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">身份证号</div>
                  <div class="input-box">
                     <el-input v-model="idCard" placeholder="请输入科身份证号" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="5" class="form-item">
                  <div class="label">电话号码</div>
                  <div class="input-box">
                      <el-input v-model="phone" placeholder="请输入科电话号码" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="1" class="form-item">
                 <div class="action-link" @click="handlerFormCollapse()">{{formCollapse?'收起':'展开'}}</div>
              </el-col>
               <div v-if="formCollapse">
                <el-col :span="6" class="form-item">
                  <div class="label">部门选择</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="department"
                      v-loading="departmentListLoading"
                    element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in departmentList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">服务老师</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="teacher" 
                      v-loading="teacherListLoading"
                    element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in teacherList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">学生状态</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="studentStatus"
                      v-loading="studentStatusListLoading"
                    element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in studentStatusList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">客户姓名</div>
                  <div class="input-box">
                      <el-input v-model="customerName" placeholder="请输入客户姓名" class="form-control"></el-input>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">服务状态</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="serviceStatus"
                      v-loading="serviceStatusListLoading"
                    element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in serviceStatusList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">学籍省份</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="province"
                    v-loading="provinceListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in provinceList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">注册来源</div>
                  <div class="input-box">
                      <el-select class="form-control" v-model="source"
                      v-loading="sourceListLoading"
                    element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in sourceList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
              <el-col :span="6" class="form-item">
                  <div class="label">所属机构</div>
                  <div class="input-box">
                     <el-select class="form-control" v-model="institution"
                     v-loading="institutionListLoading"
                    element-loading-spinner="el-icon-loading">
                        <el-option key="0" label="请选择..." value=""></el-option>
                        <el-option
                          v-for="item in institutionList"
                          :key="item.key"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                  </div>
              </el-col>
               </div>
            </el-row>

            <div class="table-container-body">
              <el-table :data="tableData" height="500" stripe style="width: 100%" ref="studentTable" class="table-body"
              :header-cell-style="{background:'#e6e6e6', color: '#646464'}">
                <el-table-column fixed type="selection" width="42"></el-table-column>
                <el-table-column fixed prop="studentName" label="学生姓名" width="120"></el-table-column>
                <el-table-column prop="tel" label="电话号码" width="150"></el-table-column>
                <el-table-column prop="customerName" label="客户姓名" width="150"></el-table-column>
                <el-table-column prop="teacher" label="服务老师" width="150"></el-table-column>
                <el-table-column
                  prop="studentStatus"
                  label="学籍省份"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="gender" label="性别" width="150">
                </el-table-column>
                <el-table-column prop="idCard" label="身份证号" width="150">
                </el-table-column>
                <el-table-column
                  prop="collegeEntranceExaminationYear"
                  label="高考年份"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  prop="currentSchool"
                  label="所在学校"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="section" label="科类" width="150">
                </el-table-column>
                <el-table-column prop="dateBirth" label="出生年月" width="150">
                </el-table-column>
                <el-table-column prop="nationality" label="民族" width="150">
                </el-table-column>
                <el-table-column
                  prop="affiliation"
                  label="所属机构"
                  width="150"
                >
                </el-table-column>
                <el-table-column
                  prop="collegeEntranceExaminationAssessmentScore"
                  label="高考估分"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="member" label="会员类别" width="150">
                </el-table-column>
                <el-table-column
                  prop="registrationSource"
                  label="注册来源"
                  width="150"
                >
                </el-table-column>
                <el-table-column prop="state" label="状态" width="150">
                </el-table-column>
                <el-table-column prop="founder" label="创建人" width="150">
                </el-table-column>
                <el-table-column
                  prop="creationTime"
                  label="创建时间"
                  width="150"
                >
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="265">
                  <template slot-scope="scope">
                    <div class="table-btn-box">
                      <div class="ct-btn view" @click="handleClick(scope.row)">
                        查看
                      </div>
                      <div class="ct-btn view" @click="handleClick(scope.row)">
                        编辑
                      </div>
                      <div class="ct-btn del">停用</div>
                      <div class="ct-btn view" @click="handleClick(scope.row)">
                        重置密码
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="table-container-footer">
             <el-pagination
                layout="total, prev, pager, next, sizes, jumper"
                :page-sizes="[10, 50, 100, 500]"
                :page-size="10"
                :total="tableData.length"
                :current-page="pageNo"
                @current-change="pageChange"
              >
              </el-pagination>
            </div>
            <el-dialog
              title="添加客户"
              width="80%"
              top="0vh"
              :visible.sync="addDialogVisible"
              :before-close="handleAddClose"
            >
              <el-divider content-position="left">基本信息</el-divider>
              <el-row>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    客户姓名
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="addCustomerName"
                      placeholder="请输入客户姓名"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    学生姓名
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="addStudentName"
                      placeholder="请输入学生姓名"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    电话号码
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="tel"
                      placeholder="请输入电话"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">身份证号</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="addIdCard"
                      placeholder="请输入18位身份证号"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">来源</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="addSourceA"
                      v-loading="addSourceAListLoading"
                    element-loading-spinner="el-icon-loading"
                      class="form-control"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in addSourceAList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">关系</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="relationA"
                      v-loading="relationAListLoading"
                    element-loading-spinner="el-icon-loading"
                      class="form-control"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in relationAList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">职业</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="profession"
                      placeholder="请输入您的职业"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">邮箱</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="mailA"
                      placeholder="请输入您的邮箱"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">意向</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="intention"
                      v-loading="intentionListLoading"
                    element-loading-spinner="el-icon-loading"
                      class="form-control"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in intentionList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">微信号</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="weChatA"
                      placeholder="请输入您的微信号"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">报名机构</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="addInstitutionA"
                      placeholder="请输入您的报名机构"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">备注</div>
                  <div class="add-input-box">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入备注"
                      class="form-control"
                      v-model="note"
                    >
                    </el-input>
                  </div>
                </el-col>
              </el-row>
              <el-divider content-position="left">学生信息</el-divider>
              <el-row>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    学生姓名
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="addStudentStudentName"
                      placeholder="请输入学生姓名"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    出生年月
                  </div>
                  <div class="add-input-box">
                    <el-date-picker
                      v-model="birthday"
                      type="date"
                      placeholder="选择日期"
                      class="form-control"
                    >
                    </el-date-picker>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    性别
                  </div>
                  <div class="add-input-box">
                    <el-radio-group class="form-radio-group form-control">
                      <el-radio v-model="sex" label="1">女</el-radio>
                      <el-radio v-model="sex" label="2">男</el-radio>
                    </el-radio-group>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    学籍省份
                  </div>
                  <div class="add-input-box">
                    <el-select v-model="addStudentProvince" class="form-control" @change="studentProvinceChange"
                      v-loading="addStudentProvinceLoading"
                      element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in provinceList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">来源</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="addSourceB"
                      v-loading="addSourceBLoading"
                      element-loading-spinner="el-icon-loading"
                      class="form-control"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in addSourceBList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    科类
                  </div>
                  <div class="add-input-box">
                    <el-select
                      v-model="subject"
                      v-loading="subjectLoading"
                      element-loading-spinner="el-icon-loading"
                      class="form-control"
                    >
                    <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in subjectList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    民族
                  </div>
                  <div class="add-input-box">
                    <el-select
                      v-model="ethnic"
                       v-loading="ethnicLoading"
                      element-loading-spinner="el-icon-loading"
                      class="form-control"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in ethnicList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">邮箱</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="mailB"
                      placeholder="请输入您的邮箱"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    所在城市
                  </div>
                  <div class="add-input-box">
                    <el-select v-model="city" class="form-control" :disabled="addStudentProvince==''"
                      @change="cityChange"
                      v-loading="cityLoading"
                      element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in cityList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">所在区县</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="district"
                      placeholder="请选择"
                      class="form-control"
                      :disabled="city==''||addStudentProvince==''"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in districtList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">报名机构</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="addStudentInstitution"
                      placeholder="请输入您的报名机构"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">录取院校</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="admissionColleges"
                      placeholder="请输入您的录取院校"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">录取专业</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="professional"
                      placeholder="请输入您的录取专业"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    学校名称
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="schoolName"
                      placeholder="请输入您的学校名称"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">
                    <div class="required-label">*</div>
                    学籍所在学校
                  </div>
                  <div class="add-input-box">
                    <el-input
                      v-model="addStudentSchoolStatus"
                      placeholder="请输入您的学籍所在学校"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">班级</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="squad"
                      placeholder="请输入您的班级"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">来源</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="addSourceC"
                      v-loading="addSourceCLoading"
                      element-loading-spinner="el-icon-loading"
                      class="form-control"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in addSourceCList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">高考分数</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="score"
                      placeholder="请输入高考分数"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">外语语种</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="language"
                      v-loading="languageLoading"
                      element-loading-spinner="el-icon-loading"
                      class="form-control"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in languageList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">邮箱</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="mailC"
                      placeholder="请输入您的邮箱"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">学生视力</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="studentVision"
                      placeholder="请输入学生视力"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">学生特长</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="studentSpecialty"
                      placeholder="请输入学生特长"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">年纪排名/总人数</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="ageRanking"
                      placeholder="请输入年纪排名/总人数"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">目标排名/总人数</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="targetPosition"
                      placeholder="请输入目标排名/总人数"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">所得奖项</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="awards"
                      placeholder="请输入所得奖项"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">邮箱</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="mailD"
                      placeholder="请输入您的邮箱"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">班主任联系方式</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="contactInformation"
                      placeholder="请输入班主任联系方式"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">港澳台意向地区（多选）</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="region"
                      multiple
                      v-loading="regionLoading"
                      element-loading-spinner="el-icon-loading"
                      class="form-control"
                    >
                      <el-option
                        v-for="item in regionList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
                <el-col :span="8" class="add-col">
                  <div class="add-label">备注</div>
                  <div class="add-input-box">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="请输入备注"
                      class="form-control"
                      v-model="noteB"
                    >
                    </el-input>
                  </div>
                </el-col>
              </el-row>
              <el-divider content-position="left">选择服务</el-divider>
              <el-row>
                <el-col :span="1" class="add-service">
                  <div class="add-label">开通服务</div>
                </el-col>
                <el-col :span="23" class="add-service">
                  <div>
                    <el-checkbox label="新高考志愿填报"></el-checkbox>
                  </div>
                  <div>
                    <el-checkbox label="港澳台院校申请服务"></el-checkbox>
                  </div>
                  <div>
                    <el-checkbox label="综合素质评价咨询服务"></el-checkbox>
                  </div>
                </el-col>
              </el-row>
              <el-divider content-position="left">其他</el-divider>
              <el-row>
                <el-col :span="8" class="add-other">
                  <div class="add-label">其他</div>
                  <div class="add-input-box">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder=""
                      class="form-control"
                      v-model="other"
                    >
                    </el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-other">
                  <div class="add-label">家长和学生意向专业排序</div>
                  <div class="add-input-box">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder=""
                      class="form-control"
                      v-model="professionalRankNote"
                    >
                    </el-input>
                  </div>
                </el-col>
                <el-col :span="8" class="add-other">
                  <div class="add-label">家长和学生意向院校及地区排序</div>
                  <div class="add-input-box">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder=""
                      class="form-control"
                      v-model="regionRankNote"
                    >
                    </el-input>
                  </div>
                </el-col>
                <div class="add-label">其他选项</div>
                <div>
                  <el-checkbox v-model="otherCheckA"
                    >是否考虑地方免费师范生</el-checkbox
                  >
                  <el-checkbox v-model="otherCheckB"
                    >是否考虑免费医学、师范定向</el-checkbox
                  >
                  <el-checkbox v-model="otherCheckC"
                    >是否考虑中外合作办学</el-checkbox
                  >
                  <el-checkbox v-model="otherCheckD"
                    >是否考虑定向、民族预科班</el-checkbox
                  >
                  <el-checkbox v-model="otherCheckE"
                    >是否考虑军校、公安院校</el-checkbox
                  >
                </div>
              </el-row>
              <el-divider content-position="left">其他</el-divider>
              <el-row>

                <el-col :span="8" class="add-col">
                  <div class="add-label">第二联系人姓名</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="secondName"
                      placeholder="请输入第二联系人姓名"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>

                <el-col :span="8" class="add-col">
                  <div class="add-label">电话</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="otherTel"
                      placeholder="请输入第二联系人电话"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>

                <el-col :span="8" class="add-col">
                  <div class="add-label">关系</div>
                  <div class="add-input-box">
                    <el-select
                      v-model="relationB"
                      v-loading="relationBLoading"
                      element-loading-spinner="el-icon-loading"
                      class="form-control"
                    ><el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in relationBList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>

                <el-col :span="8" class="add-col">
                  <div class="add-label">微信号</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="weChatB"
                      placeholder="请输入您的微信号"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>

                <el-col :span="8" class="add-col">
                  <div class="add-label">邮箱</div>
                  <div class="add-input-box">
                    <el-input
                      v-model="mailE"
                      placeholder="请输入您的邮箱"
                      class="form-control"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>

              <span slot="footer" class="dialog-footer">
                <div class="btn-box">
                  <div
                    class="btn-dialog-cancel"
                    @click="addDialogVisible = false"
                  >
                    关闭
                  </div>
                  <div
                    class="btn-dialog-confirm"
                    @click="addDialogVisible = false"
                  >
                    保存
                  </div>
                </div>
              </span>
            </el-dialog>
            <el-dialog
              title="选择客户"
              :visible.sync="dialogVisible">
              <div class="custom-dialog-body">
                 <el-row class="form-group">
                    <el-col :span="6" class="form-item">
                        <div class="label">姓名</div>
                        <div class="input-box">
                          <el-input v-model="pushCustomerName" placeholder="请输入姓名" class="form-control"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="6" class="form-item">
                        <div class="label">机构名称</div>
                        <div class="input-box">
                          <el-input v-model="pushOrganization" placeholder="请输入机构名称" class="form-control"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="6" class="form-item">
                        <div class="label">联系方式</div>
                        <div class="input-box">
                          <el-input v-model="pushTel" placeholder="请输入联系方式" class="form-control"></el-input>
                        </div>
                    </el-col>
                    <el-col :span="2" class="form-item">
                        <div class="search-btn" @click="handlePushListSearch()">查询</div>
                    </el-col>
                 </el-row>
                 <div class="table-container-body">
                    <el-table :data="customerTableData" height="500" stripe class="table-body" 
                    :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
                    v-loading="customerTableLoading">
                      <el-table-column fixed type="selection" width="42" label="选择">
                      </el-table-column>
                      <el-table-column prop="name" label="姓名">
                      </el-table-column>
                      <el-table-column prop="tel" label="联系方式">
                      </el-table-column>
                      <el-table-column prop="job" label="岗位">
                      </el-table-column>
                      <el-table-column prop="organization" label="机构名称">
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="table-container-footer">
                    <el-pagination
                      layout="total, prev, pager, next, sizes, jumper"
                      :page-sizes="[10, 50, 100, 500]"
                      :page-size="customerTablePageSize"
                      :total="customerTableTotal"
                      :current-page="customerTablePageNo"
                      @current-change="customerTablePageChange"
                      @size-change="customerTableSizeChange"
                    >
                    </el-pagination>
                  </div>
              </div>
              <span slot="footer" class="dialog-footer">
                <div class="confirm-btn" @click="dialogVisible = false">确  认</div>
              </span>
            </el-dialog>
          </div>
</template>

<script>
export default {
  name: "customerC",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      formCollapse: false,
      addDialogVisible: false,
      distributeDialogVisible: false,
      dialogVisible:false,
      serviceListLoading:false,
      departmentListLoading:false,
      teacherListLoading:false,
      studentStatusListLoading:false,
      serviceStatusListLoading:false,
      provinceListLoading:false,
      sourceListLoading:false,
      institutionListLoading:false,
      service:"",
      studentName:"",
      idCard:"",
      phone:"",
      department:"",
      teacher:"",
      studentStatus:"",
      customerName:"",
      serviceStatus:"",
      province:"",
      source:"",
      institution:"",

      //添加页面
      addStudentProvinceLoading:false,
      cityLoading:false,
      addSourceAListLoading:false,
      relationAListLoading:false,
      intentionListLoading:false,
      addSourceBLoading:false,
      subjectLoading:false,
      ethnicLoading:false,
      addSourceCLoading:false,
      languageLoading:false,
      regionLoading:false,
      relationBLoading:false,
      addCustomerName:"",
      addStudentName:"",
      tel:"",
      addIdCard:"",
      addSourceA:"",
      relationA:"",
      profession:"",
      mailA:"",
      intention:"",
      weChatA:"",
      addInstitutionA:"",
      note:"",
      addStudentStudentName:"",
      birthday:"",
      sex:"",
      addStudentProvince:"",
      addSourceB:"",
      subject:"",
      ethnic:"",
      mailB:"",
      city:"",
      district:"",
      addStudentInstitution:"",
      admissionColleges:"",
      professional:"",
      schoolName:"",
      addStudentSchoolStatus:"",
      squad:"",
      addSourceC:"",
      score:"",
      language:"",
      mailC:"",
      studentVision:"",
      studentSpecialty:"",
      ageRanking:"",
      targetPosition:"",
      awards:"",
      mailD:"",
      contactInformation:"",
      region:[],
      noteB:"",
      addSourceAList:[],
      addSourceBList:[],
      addSourceCList:[],
      relationAList:[],
      relationBList:[],
      //缺少开通服务
      other:"",
      professionalRankNote:"",
      regionRankNote:"",
      otherCheckA:"",
      otherCheckB:"",
      otherCheckC:"",
      otherCheckD:"",
      otherCheckE:"",
      secondName:"",
      otherTel:"",
      relationB:"",
      weChatB:"",
      mailE:"",

      //推送页面
      pushCustomerName:"",
      pushOrganization:"",
      pushTel:"",
      customerTableLoading:false,
      customerTableData:[],
      customerTablePageSize:10,
      customerTablePageNo:1,
      customerTableTotal:0,

      institutionList:[],
      cityList:[],
      regionList:[],
      teacherList:[],
      studentStatusList:[],
      districtList:[],
      languageList:[],
      serviceStatusList:[],
      departmentList:[],
      ethnicList:[],
      intentionList:[],
      relationList:[],
      serviceList:[],
      provinceList:[],
      subjectList:[],
      sourceList:[],
      ethnicList:[],
      
      tableData: [],
    };
  },
  methods: {
    initSelectList(){
      //服务区分
      this.serviceListLoading = true;
      this.$http.post(this.$apis.getServiceList).then(r=>{
        this.serviceListLoading = false;
        if(r.data && r.data.list){
          this.serviceList = r.data.list.map(m=>{
            return {
              key:m.id,
              label:m.serviceName,
              value:m.id
            }
          });
        }
      }); 
      //部门选择
      this.departmentListLoading = true;
      this.$http.post(this.$apis.getDepartmentList+"?type=dept").then(r=>{
        this.departmentListLoading = false;
        if(r.data){
          this.departmentList = r.data.map(m=>{
            return {
              key:m.id,
              label:m.deptName,
              value:m.id
            }
          });
        }
      });
      //服务老师
      this.teacherListLoading = true;
      this.$http.post(this.$apis.getTeacherList).then(r=>{
        this.teacherListLoading = false;
        if(r.data && r.data.list){
          this.teacherList = r.data.list.map(m=>{
            return {
              key:m.id,
              label:m.teacherName,
              value:m.id
            }
          });
        }
      });
      //学生状态
      this.studentStatusListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=student_status").then((res) => {
        this.studentStatusListLoading = false;
        if (res.data) {
          this.studentStatusList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //服务状态
      this.serviceStatusListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=service_status").then((res) => {
        this.serviceStatusListLoading = false;
        if (res.data) {
          this.serviceStatusList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //获取省份
      this.provinceListLoading = true;
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          //this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //来源
      this.sourceListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=register_source").then((res) => {
        this.sourceListLoading = false;
        if (res.data) {
          this.sourceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //所属机构
      this.institutionListLoading = true;
      this.$http.post(this.$apis.getDepartmentList+"?type=institution").then(r=>{
        this.institutionListLoading = false;
        if(r.data){
          this.institutionList = r.data.map(m=>{
            return {
              key:m.id,
              label:m.deptName,
              value:m.id
            }
          });
        }
      });
    },
    initTableData(){
      var data ={
          phone: this.phone,
          province: this.province,
          serviceId: "",
          studentName: this.studentName
      };
      this.$http.post(this.$apis.getStuderntByPage,data).then(r=>{
        if(r.data && r.data.list){
          this.tableData = r.data.list.map(m=>{
            return {
              id:m.id,
              studentName: m.studentName,
              phone: m.phone,
              customerName: m.customerName,
              teacher: m.teacher,
              province: m.province,
              gender: m.gender,
              idCard: m.idCard,
              examYear: m.examYear,
              currentSchool: m.currentSchool,
              section: m.section,
              dateBirth: m.dateBirth,
              nationality: m.nationality,
              affiliation: m.affiliation,
              estimatedScore: m.estimatedScore,
              member: m.member,
              Source: m.Source,
              state: m.state,
              founder: m.founder,
              creationTime: m.creationTime,
            }
          });
          this.tableTotal = r.data.total;
        }
      });
    },
    handleAddClick(){
      this.addDialogVisible=true;
      this.initAddSelectList();
    },
    initAddSelectList(){
      //来源
      this.addSourceAListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=register_source").then((res) => {
        this.addSourceAListLoading = false;
        if (res.data) {
          this.addSourceAList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //关系
      this.relationAListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=relate").then((res) => {
        this.relationAListLoading = false;
        if (res.data) {
          this.relationAList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //意向
      this.$http.post(this.$apis.getDictionary+"?type=intention").then((res) => {
        if (res.data) {
          this.intentionList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      this.addStudentProvinceLoading = true;
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        this.addStudentProvinceLoading = false;
        if (res.data) {
          //this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //来源B
      this.addSourceBListLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=register_source").then((res) => {
        this.addSourceBListLoading = false;
        if (res.data) {
          this.addSourceBList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //获取科类
      this.subjectLoading = true;
      this.$http.post(this.$apis.getCourseList).then((res) => {
        this.subjectLoading = false;
        if (res.data) {
          this.subjectList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //民族
      this.ethnicLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=nation").then((res) => {
        this.ethnicLoading = false;
        if (res.data) {
          this.ethnicList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //来源C
      this.addSourceCListLoading = false;
      this.$http.post(this.$apis.getDictionary+"?type=register_source").then((res) => {
        this.addSourceCListLoading = true;
        if (res.data) {
          this.addSourceCList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //外语语言
      this.languageLoading = true;
      this.$http.post(this.$apis.getDictionary+"?type=foreign_language").then((res) => {
        this.languageLoading = false;
        if (res.data) {
          this.languageList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
      //港澳台意向地区
      // this.regionLoading = false;
      // this.$http.post(this.$apis.getSarSchool).then(r=>{
        // this.regionLoading = true;
      //   if(r.data && r.data.list){
      //     this.regionList = res.data.list.map(m=>{
      //       return {
      //         key:m.id,
      //         label:m.city,
      //         value:m.id
      //       }
      //     });
      //   }
      // });
      this.regionList = [{
        key:1,
        label:"香港",
        value:1
      },{
        key:2,
        label:"澳门",
        value:2
      },{
        key:3,
        label:"台湾",
        value:3
      }
      ]
      //关系B
      this.relationBListLoading = false;
      this.$http.post(this.$apis.getDictionary+"?type=relate").then((res) => {
        this.relationBListLoading = true;
        if (res.data) {
          this.relationBList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
    },
    initStudentCitySelectList(){
      this.cityLoading = true;
      //获取城市
      this.$http.post(this.$apis.getProvinceOrCityList+"?parentId="+this.addStudentProvince).then((res) => {
        this.cityLoading = false;
        if (res.data) {
          this.cityList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
    },
    initDistrictSelectList(){
      //获取区县
      this.$http.post(this.$apis.getProvinceOrCityList+"?parentId="+this.city).then((res) => {
        if (res.data) {
          this.districtList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
    },
    initStudentTable(){
      //todo
    },
    initCustomerTable(){
      this.customerTableData = [];
    },
    studentProvinceChange(){
      this.initStudentCitySelectList();
      this.city="";
      this.district="";
    },
    cityChange(){
      this.initDistrictSelectList();
      this.district="";
    },
    handleSearchClick(){
      this.initTableData();
      //this.initstudentTable();
    },
    handlePushClick(){
      let selectData = this.$refs.studentTable.selection;
      if(selectData.length<=0){
        this.$message.warning("请先选择需要分配的学生");
          return;
      }
      this.dialogVisible = true;
      this.initCustomerTable();
    },
    handleClick(row) {
      console.log(row);
    },
    handlerFormCollapse(){
      this.formCollapse = !this.formCollapse;
    },
    pageChange(val) {
      
    },
    customerTablePageChange(val){
      this.customerTablePageNo = val;
      this.initCustomerTable();
    },
    customerTableSizeChange(val){
      this.customerTablePageSize = val;
      this.initCustomerTable();
    },
    handlePushListSearch(){
      this.customerTablePageNo = 1;
      this.initCustomerTable();
    },
    handleAddClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
  mounted(){
    this.initSelectList();
    this.initTableData();
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-table__body-wrapper {
    height: 90% !important;
}
.customer-c {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .serrch-box {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;

    .search-item {
      display: flex;
      align-items: center;
      width: 30%;
      padding: 15px 0;
      .label {
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 80px;
        display: flex;
        .required-label {
          color: rgb(255, 0, 0);
        }
      }
      .s-box {
        width: calc(100% - 100px);
      }
    }
  }

  .btn-box {
    display: flex;
    align-items: center;
    .btn-dialog-cancel {
      line-height: 32px;
      width: 95px;
      border-radius: 4px;
      background-color: #fff;
      text-align: center;
      color: #000;
      margin-left: 15px;
      cursor: pointer;
      font-size: 14px;
      border: 1px solid #c9c9c9;
    }
    .btn-dialog-confirm {
      line-height: 32px;
      width: 95px;
      border-radius: 4px;
      background-color: rgba(255, 69, 11, 100);
      text-align: center;
      color: #fff;
      margin-left: 15px;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .add-other {
    padding: 15px 0px;
    .add-input-box {
      display: flex;
      width: calc(100% - 100px);
    }
  }
  .add-service{
    padding: 15px 0px;
  }
  .add-col {
    padding: 15px 0px;
    display: flex;
    align-items: center;
    .add-label {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      width: 120px;
      .required-label {
        color: rgb(255, 0, 0);
      }
    }
    .add-input-box {
      display: flex;
      width: calc(100% - 100px);
    }
  }
  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
        height: 55px;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 14px;
          cursor:pointer;
          color: #ff450b;
      }
    }
  }
  .form-control {
    width: 100%;
  }
  .form-radio-group {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      border: 1px solid #e6e6e6;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
      .ct-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
      .ct-btn.del {
        color: rgba(255, 148, 75, 100);
        border: 1px solid rgba(255, 148, 75, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
  .custom-dialog-body{
    width: 100%;
    .form-group{
      padding:0 !important;
      .form-item{
        padding:0 0 15px 0 !important;
        height: 55px;
      }
    }
    .search-btn {
        line-height: 32px;
        width: 100%;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        cursor: pointer;
        font-size: 14px;
    }
    .table-container-body{
      padding:0 !important;
    }
    .table-container-footer{
      padding: 10px 0;
      border:solid #e6e6e6;
      border-width: 0 1px 1px 1px;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    .confirm-btn{
      line-height: 32px;
      width: 60px;
      border-radius: 4px;
      background-color: rgba(255, 69, 11, 100);
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
</style>